<template>
  <v-container class="pa-0 white elevation-6 transparent rounded-t-2" fluid>
    <v-card elevation="0">
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
        {{ this.$t('accounting.lang_accounting_displayDatev') }}
      </v-card-title>
      <v-card-text class="pb-4">
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col cols="12" md="4" sm="12">
              <v-select :items="this.cashiers" :label="$t('generic.lang_kasse')" dense item-text="name"
                        item-value="id" outlined v-model="cashierID"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field dense :label="$t('generic.lang_outputReport')" outlined type="date" v-model="tsTimeStart"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field dense :label="$t('generic.lang_till')" outlined type="date" v-model="tsTimeEnd"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div class="dropdown-menu-header-inner bg-strong-bliss">
                  <div class="menu-header-image dd-header-bg-3"></div>
                  <div class="menu-header-content" style="height: 70px">
                  </div>
                </div>

              </div>
              <div class="card-body"><p class="text-justify">{{ $t('generic.lang_datevCsvExportFileViaRevenueClearingAccount') }}</p></div>
              <div class="d-block text-right card-footer">
                <button @click="exportCSV('datev_buchungenERLVER')" class="btn btn-success btn-lg btn-block">{{$t('generic.lang_download')}}</button>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div class="dropdown-menu-header-inner bg-deep-blue">
                  <div class="menu-header-image dd-header-bg-3"></div>
                  <div class="menu-header-content" style="height: 70px">
                  </div>
                </div>

              </div>
              <div class="card-body"><p class="text-justify">{{ $t('generic.lang_datevCsvExportFileWithoutRevenueClearingAccount') }}</p></div>
              <div class="d-block text-right card-footer">
                <button  @click="exportCSV('datev_buchungenKASSE')" class="btn btn-success btn-lg btn-block">{{$t('generic.lang_download')}}</button>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div class="dropdown-menu-header-inner bg-deep-blue">
                  <div class="menu-header-image dd-header-bg-3"></div>
                  <div class="menu-header-content" style="height: 70px">
                  </div>
                </div>

              </div>
              <div class="card-body"><p class="text-justify">{{ $t('accounting.lang_datevCashBookOnline') }}</p></div>
              <div class="d-block text-right card-footer">
                <button  @click="exportCSV('datev_kassenbuchOnline')" class="btn btn-success btn-lg btn-block">{{$t('generic.lang_download')}}</button>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div class="dropdown-menu-header-inner bg-arielle-smile">
                  <div class="menu-header-image dd-header-bg-3"></div>
                  <div class="menu-header-content" style="height: 70px">
                  </div>
                </div>

              </div>
              <div class="card-body"><p class="text-justify">{{ $t('accounting.lang_lexwareCsvExportFileViaRevenueClearingAccount') }}</p></div>
              <div class="d-block text-right card-footer">
                <button @click="exportCSV('buchungenLexware')" class="btn btn-success btn-lg btn-block">{{$t('generic.lang_download')}}</button>
              </div>
            </div>
          </v-col>


          <v-col v-if="false" cols="12" md="4" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div class="dropdown-menu-header-inner bg-happy-fisher">
                  <div class="menu-header-image dd-header-bg-3"></div>
                  <div class="menu-header-content" style="height: 70px">
                  </div>
                </div>

              </div>
              <div class="card-body"><p class="text-justify">{{ $t('accounting.lang_varialCsvExportFileWithoutRevenueClearingAccount') }}</p></div>
              <div class="d-block text-right card-footer">
                <button disabled @click="exportCSV('buchungenVarial')" class="btn btn-success btn-lg btn-block">{{$t('generic.lang_download')}}</button>
              </div>
            </div>
          </v-col>
          <v-col v-if="true" cols="12" md="4" sm="6">
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card">
              <div class="pa-0 ma-0 dropdown-menu-header">

                <div class="dropdown-menu-header-inner bg-heavy-rain">
                  <div class="menu-header-image dd-header-bg-3"></div>
                  <div class="menu-header-content" style="height: 70px">
                  </div>
                </div>

              </div>
              <div class="card-body"><p class="text-justify">{{ $t('accounting.lang_sapExport') }}</p></div>
              <div class="d-block text-right card-footer">
                <button @click="exportCSV('buchungen_SAP')" class="btn btn-success btn-lg btn-block">{{$t('generic.lang_download')}}</button>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Events} from "../../../plugins/events";
import {ENDPOINTS} from "@/config";
import {mapState} from "vuex";

export default {
  name: "DatevExportComponent",
  data: () => {
    return {
      cashiers: [],
      cashierID:0,
      tsTimeStart:"",
      tsTimeEnd:"",

    }
  },
  computed: {
    ...mapState([
      'api'
    ])
  },
  mounted() {
    this.loadCashiers();
  },
  methods: {
    loadCashiers() {
      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashiers = res.data.cashierIDs.map((elt) => {
          return {
            id: elt[0],
            name: this.$t('generic.lang_kasse') + ' ' + elt[0] + ' '+elt[1]
          }
        });
      })
    },

    exportCSV(url) {
      let apiServerIP = process.env.VUE_APP_API_HOST;
      if(window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de'){
        let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.open(apiServerIP + "/get/accounting/datev/exports/" + url + ".php?download=1&datevCashierID=" + this.cashierID + "&fromTime=" + this.tsTimeStart + "&toTime=" + this.tsTimeEnd + "&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");

      /*
      this.axios.post(url, this.form).then((res) => {
        const blobContent = new Blob([res.data], {type: "application/csv"});
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blobContent);
        a.setAttribute('target', '_blank');
        a.click();
        a.remove();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      })
      */
    }
  }
}
</script>

<style scoped>
.dd-header-bg-3 {
  background-image: url('./../../../assets/images/dropdown-header/abstract1.jpg');
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}

.dropdown-menu-header {
  z-index: 0 !important;
}
</style>